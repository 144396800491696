<template>
  <v-container>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account" :title="$t(title)">
          <v-card>
            <v-card-text>
              <customers-form
                :title="$t('new', { name: $tc('customer') })"
                :customer="customer"
                :view="true"
                @validated="saveCustomer()"
              >
                <template>
                  <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                    v-if="$store.getters['auth/isStudio']"
                  >
                    <label v-text="$t('customers.contact')" />
                    <v-select
                      dense
                      outlined
                      v-model="customer.contact"
                      class="inputs"
                      single-line
                      :items="tattooers"
                      item-value="id"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.user.first_name }}
                        {{ data.item.user.last_name }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.user.first_name }}
                        {{ data.item.user.last_name }}
                      </template>
                    </v-select>
                  </v-col>
                </template>
              </customers-form>
            </v-card-text>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants";
export default {
  name: "CustomerNewWithoutAPDate",
  components: { CustomersForm: () => import("@/components/customer/Form") },
  data() {
    return {
      customer: {
        user: { role_id: constants.roles.CUSTOMER },
      },
      tattooers: [],
    };
  },
  mounted() {
    this.fetchActiveTattooer();
  },
  methods: {
    ...mapActions("customers", ["addCustomerWithoutDate"]),
    saveCustomer() {
      this.addCustomerWithoutDate({ customer: this.customer }).then(
        (response) => {
          if (response) {
            this.errors.clear();
            this.$alert(this.$t("customer_create_success"), "", "");
          }
          this.$router.back();
        }
      );
    },
    ...mapActions("studios", ["getActiveTattooer"]),
    fetchActiveTattooer() {
      this.getActiveTattooer().then((data) => {
        this.tattooers = data.map((x) => x.tattooer);
        let user = this.$store.state.auth.user;
        this.tattooers.push({
          id: user.id,
          user: { first_name: user.studio.studio_name },
        });
      });
    },
  },
};
</script>
<style lang="sass" scoped>
label
  display: block
  height: 35px !important
</style>